import React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';


const StyledButton = styled(Button)(({ size, icon }) => ({
  border: `2px solid #00B894`, 
  borderRadius: '4px',
  padding: size === 'small' ? '8px 12px' : '12px 20px', 
  textTransform: "capitalize",
  fontFamily: 'DM Sans', 
  fontSize:'15px',
  '&:hover': {
    backgroundColor: '#00B894', 
    color: '#fff', 
  },
  color: "#525455",
  fontWeight: "bold",
  '& > *:first-child': { 
    marginRight: '8px', 
  },
}));

const ButtonCommon = ({ 
  children, 
  size = 'medium', 
  icon, 
  onClick, 
}) => {
  return (
    <StyledButton 
      size={size} 
      onClick={onClick} 
      variant="outlined" 
    >
      {icon && icon} 
      {children}
    </StyledButton>
  );
};

export default ButtonCommon;