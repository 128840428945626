import React, { useState } from "react";
import {
  Box,
} from "@mui/material";


import CommonHeader from "../../CommonComponent/CommonHeader";
import CommonTabs from "../../CommonComponent/CommonTabs";
import { ServicesCards } from "./ServicesCards";
import CategoryCards from "./CategoryCards";

const VendorManagement = () => {
  const [value, setValue] = useState("1");

  const tabs = [
    { label: "Data Processing Services", value: "1", content: <ServicesCards />},
    { label: "Categories", value: "2", content: <CategoryCards /> },
  ];

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", }}>
      <CommonHeader
        title="Vendor Management"
        subTitle="Lorem Ipsum is simply dummy text 's standard dummy text ever since the 1500s"
      />
    
      <CommonTabs value={value} handleChange={handleChange} tabs={tabs} />
      
    </Box>
  );
};

export default VendorManagement;
