import React, { useState, useEffect } from "react";
import {
    Drawer,
    Box,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
    IconButton,
    Avatar,
    Button,
    Divider,
    Menu,
    MenuItem,
    Snackbar
} from "@mui/material";
import { Modal } from "@mui/material";
import {
    KeyboardArrowDown as ArrowDownIcon,
    DeleteOutline,
    UnfoldMore,
    CloseOutlined
} from "@mui/icons-material";
import { DashboardIcon, ScannerIcon, PeopleIcon, ConsentIcon, SettingIcon, HelpIcon, Speedometer, DataPrivacyLogo } from '../../assets/svg';
import UpgradeLogo from "../../assets/svg/upgrade.svg";
import { useNavigate } from "react-router-dom";
import { fetchUserAttributes, getCurrentUser, signOut } from '@aws-amplify/auth';
import { useLocation } from "react-router-dom";
import axios from 'axios';

const drawerWidth = 280;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: '#FFFFFF',
    borderRadius: "16px",
    boxShadow: 24,
    p: 3,
};

const Sidebar = () => {
    const [selectedItem, setSelectedItem] = useState("Dashboard");
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElWebsite, setAnchorElWebsite] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [selectedWebsiteId, setSelectedWebsiteId] = useState(null);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleWebisteClick = (event) => {
        setAnchorElWebsite(event.currentTarget);
    };

    const handleCloseProfile = () => {
        setAnchorEl(null);
    }

    const handleClose = () => {
        setAnchorElWebsite(null);
    };

    const handleLogout = async () => {
        try {
            const res = await signOut();
            console.log(res, "========> log out");
            sessionStorage.clear();
            localStorage.clear();
            setSnackbarOpen(true);
            navigate('/login');
        } catch (err) {
            console.log(err, "err");
        }
    }

    const navigate = useNavigate(); // Use useNavigate hook

    const location = useLocation();

    const menuToRouteMap = {
        "Dashboard": "/dashboard",
        "Website Scan": "/website-scan",
        "Banner Designer": "/banner-designer",
        "Vendor Management": "/vendor-management",
        "Consent Log": "/consent-log",
        "Settings": "/settings",
        "Help & Support": "/help"
    };

    const routeToMenuMap = {
        "/dashboard": "Dashboard",
        "/website-scan": "Website Scan",
        "/banner-designer": "Banner Designer",
        "/vendor-management": "Vendor Management",
        "/consent-log": "Consent Log",
        "/settings": "Settings",
        "/help": "Help & Support"
    };

    useEffect(() => {
        const currentMenuItem = routeToMenuMap[location.pathname];
        if (currentMenuItem) {
            setSelectedItem(currentMenuItem);
        }
        // eslint-disable-next-line
    }, [location.pathname]);

    const handleMenuItemClick = (item) => {
        setSelectedItem(item);
        navigate(menuToRouteMap[item]);
    };

    const [open, setOpen] = React.useState(false);

    const [userInfo, setUserInfo] = useState(null);

    const fetchCurrentUser = async () => {
        const data = await getCurrentUser();
        console.log(data.signInDetails?.userStatus, "=>>>>> status");

        console.log(data, "'====> data");

        const currentUser = await fetchUserAttributes();
        setUserInfo(currentUser);
    }

    useEffect(() => {
        fetchCurrentUser();
    }, []);

    const renderIcon = (item, isSelected) => {
        const iconColor = isSelected ? '#00B894' : '';

        switch (item) {
            case "Dashboard":
                return <DashboardIcon color={iconColor} />;
            case "Website Scan":
                return <ScannerIcon color={iconColor} />;
            case "Banner Designer":
                return <Speedometer color={iconColor} />;
            case "Vendor Management":
                return <PeopleIcon color={iconColor} />;
            case "Consent Log":
                return <ConsentIcon color={iconColor} />;
            case "Settings":
                return <SettingIcon color={iconColor} />;
            case "Help & Support":
                return <HelpIcon color={iconColor} />;
            default:
                return null;
        }
    };

    const [allWebsites, setAllWebsites] = useState([]);

    const getInitial = (name) => {
        return name ? name.charAt(0).toUpperCase() : '';
    };

    useEffect(() => {
        const getAllWebsites = () => {
            axios.get("http://192.168.1.62:3001/website/get_all_website?organization_id=1")
                .then((response) => setAllWebsites(response.data.data))
                .catch((error) => console.error(error));
        };
        getAllWebsites();
    }, []);

    console.log(userInfo, '=====> userinfo');

    // Function to handle the selection of a website ID
    const handleDeleteClick = (websiteId) => {
        setSelectedWebsiteId(websiteId);
        setOpen(true); // Open the modal
    };

    const handleDelete = async (websiteId) => {
        try {
            const response = await axios.delete(`http://192.168.1.62:3001/website/delete_website?organization_id=1&website_id=${websiteId}`);
            console.log(response.data);
            setSnackbarMessage(response.data);
            setOpen(false); // Close the modal after successful deletion
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Drawer
            variant="permanent"
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                    width: drawerWidth,
                    boxSizing: "border-box",
                    bgcolor: "#F9FAFB",
                    border: "none",
                    borderRight: "1px solid #EAEAEA",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between", // Ensure no scrolling by distributing space
                },
            }}
        >
            {/* Top Section */}
            <Box sx={{ p: 1 }}>
                {/* Header Section */}
                <Box sx={{ mb: 1, p: 2 }}>
                    <DataPrivacyLogo width="150" height="29" style={{ marginBottom: "10px" }} />
                    <Divider sx={{ mb: 2 }} />
                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                        <Avatar sx={{ width: 30, height: 30, mr: 2 }}>A</Avatar>
                        <Box sx={{ flexGrow: 1 }}>
                            <Typography fontWeight="500">
                                Hello, {userInfo?.given_name}
                            </Typography>
                        </Box>
                        <IconButton onClick={handleClick}>
                            <ArrowDownIcon />
                        </IconButton>

                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleCloseProfile}
                        >
                            <MenuItem onClick={handleClose}>Profile</MenuItem>
                            <Divider />
                            <MenuItem onClick={handleLogout}>Sign Out</MenuItem>
                        </Menu>
                        <Snackbar
                            open={snackbarOpen}
                            autoHideDuration={3000} // Automatically close after 3 seconds
                            onClose={() => setSnackbarOpen(false)} // Close handler
                            message={"Successfully logged out!"}
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Position: Top-right
                        />
                    </Box>
                    <Divider sx={{ mb: 2 }} />
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <Box>
                            <Typography variant="body2" fontWeight="400" color="textSecondary" sx={{ mb: 1 }}>
                                Websites
                            </Typography>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Box
                                    sx={{
                                        width: 25,
                                        height: 25,
                                        borderRadius: "50%",
                                        border: "1px solid #DADADA",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        mr: 1 // Adjust the margin right to add some space between the box and the text
                                    }}
                                >
                                    <Typography color="#00B894">D</Typography>
                                </Box>
                                <Typography variant="body2" fontWeight="bold" color="#5F5F5F">
                                    datavizz.in
                                </Typography>
                            </Box>

                        </Box>
                        <IconButton size="small" onClick={handleWebisteClick}>
                            <UnfoldMore />
                        </IconButton>
                        <Menu
                            anchorEl={anchorElWebsite}
                            open={Boolean(anchorElWebsite)}
                            onClose={handleClose}
                        >
                            {(allWebsites || []).map((website) => (
                                <MenuItem key={website.website_id}>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <Box
                                            sx={{
                                                width: 25,
                                                height: 25,
                                                borderRadius: "50%",
                                                border: "1px solid #DADADA",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                mr: 1
                                            }}
                                        >
                                            <Typography color="#00B894">{getInitial(website.domain_name)}</Typography> {/* Replace with dynamic initials or another indicator if needed */}
                                        </Box>
                                        <Typography variant="body2" color="#5F5F5F" display="flex" alignItems="center">
                                            {website.domain_name}
                                            <DeleteOutline sx={{ width: "20px", ml: 4 }} onClick={() => handleDeleteClick(website.website_id)} />
                                        </Typography>
                                    </Box>
                                </MenuItem>
                            ))}
                            <Divider />
                            <MenuItem onClick={handleClose}>
                                <Button color="#525455" sx={{ fontWeight: "700", textTransform: "capitalize" }}> + Add New Website </Button>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Box>
                <Divider sx={{ mb: 2 }} />

                <Modal open={open} onClose={handleClose}>
                    <Box sx={style}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="h6" color="#525455" fontWeight="bold">Delete Website</Typography>
                            <IconButton onClick={() => setOpen(false)}>
                                <CloseOutlined />
                            </IconButton>
                        </Box>
                        <Typography color="#989898">Are you sure, you want to delete this website, It will affect in your all data privacy</Typography>
                        <Divider sx={{ my: 2 }} />
                        <Box gap={2} display="flex">
                            <Button variant="outlined" sx={{ border: "2px solid #00B894", textTransform: "capitalize", fontWeight: "bold" }} color="#525455" onClick={() => handleDelete(selectedWebsiteId)}> Delete</Button>
                            <Button variant="outlined" sx={{ border: "2px solid #CECECE", textTransform: "capitalize", fontWeight: "bold" }} color="#525455" onClick={() => setOpen(false)}> Cancel</Button>
                        </Box>
                    </Box>
                </Modal>

                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={() => setSnackbarOpen(false)}
                    message={snackbarMessage}
                />

                {/* Main Menu */}
                <Box>
                    <Typography variant="subtitle2" color="textSecondary" sx={{ mb: 1, ml: 2 }}>
                        MAIN MENU
                    </Typography>
                    <List>
                        {[
                            "Dashboard",
                            "Website Scan",
                            "Banner Designer",
                            "Vendor Management",
                            "Consent Log",
                        ].map((item) => (
                            <ListItemButton
                                key={item}
                                onClick={() => handleMenuItemClick(item)}
                                selected={selectedItem === item}
                                sx={{
                                    backgroundColor: selectedItem === item ? "#E6F4F1" : "transparent",
                                    "&:hover": {
                                        backgroundColor: "#E6F4F1",
                                    },
                                    "&.Mui-selected": {
                                        backgroundColor: "#E6F4F1 !important",
                                        "&:hover": {
                                            backgroundColor: "#E6F4F1",
                                        },
                                    },
                                    '& .MuiListItemIcon-root': {
                                        color: selectedItem === item ? '#00B894' : 'inherit',
                                    },
                                    '& .MuiListItemText-primary': {
                                        color: selectedItem === item ? "#00B894" : "inherit",
                                    },
                                    borderRadius: "8px",
                                    mx: 1,
                                    my: 0.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: '40px', '& svg': {
                                            width: '20px',
                                            height: '20px',
                                            // Apply green filter when selected
                                            filter: selectedItem === item ?
                                                'invert(46%) sepia(72%) saturate(2499%) hue-rotate(133deg) brightness(96%) contrast(105%)' :
                                                'none'
                                        }
                                    }}>
                                    {renderIcon(item, selectedItem === item)}
                                </ListItemIcon>
                                <ListItemText primary={item} />
                            </ListItemButton>
                        ))}
                    </List>
                </Box>
            </Box>

            {/* Upgrade Card */}
            <Box
                sx={{
                    p: 2,
                    mx: 2,
                    borderRadius: 2,
                    bgcolor: "#fff",
                    boxShadow: 1,
                    textAlign: "left",
                    mt: 8,
                }}
            >
                <img
                    src={UpgradeLogo}
                    alt="Upgrade"
                    style={{
                        width: "40%",
                        height: "auto",
                    }}
                />
                <Typography variant="subtitle1" fontWeight="bold" gutterBottom color="#525455">
                    Want More Feature
                </Typography>
                <Typography variant="body2" color="#989898" gutterBottom mb={2}>
                    Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry.
                </Typography>
                <Button
                    variant="contained"
                    sx={{ textTransform: "none", borderRadius: '8px', backgroundColor: '#00B894', fontWeight: "bold" }}
                >
                    Upgrade
                </Button>
            </Box>

            {/* Footer Section */}
            <Box sx={{ p: 2 }}>
                <Typography variant="subtitle2" color="textSecondary" sx={{ mb: 1, ml: 2 }}>
                    OTHER
                </Typography>
                <List>
                    <ListItemButton onClick={() => handleMenuItemClick("Settings")}>
                        <ListItemIcon>
                            <SettingIcon />
                        </ListItemIcon>
                        <ListItemText primary="Settings" />
                    </ListItemButton>
                    <ListItemButton onClick={() => handleMenuItemClick("Help & Support")}>
                        <ListItemIcon>
                            <HelpIcon />
                        </ListItemIcon>
                        <ListItemText primary="Help & Support" />
                    </ListItemButton>
                </List>
            </Box>
        </Drawer>
    );
};

export default Sidebar;