import React from 'react';
import {
    Box,
    Container,
    Typography,
    TextField,
    Button,
    Checkbox,
    FormControlLabel,
    Link,
    Paper,
    FormHelperText,
} from '@mui/material';
import myIcon from '../assets/svg/logo.svg';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { useNavigate } from 'react-router-dom';

const SignupPage = () => {
    const [showPassword, setShowPassword] = React.useState(false);
    const [formData, setFormData] = React.useState({
        fullName: '',
        email: '',
        password: '',
        isAdmin: true,
        acceptTerms: false,
    });
    const [errors, setErrors] = React.useState({});
    const [touched, setTouched] = React.useState({});
    const navigate = useNavigate();

    const handleClickShowPassword = () => setShowPassword(!showPassword);

    const validateField = (name, value) => {
        switch (name) {
            case 'fullName':
                if (!value.trim()) {
                    return 'Full name is required';
                }
                if (value.trim().length < 2) {
                    return 'Full name must be at least 2 characters long';
                }
                if (!/^[a-zA-Z\s]*$/.test(value)) {
                    return 'Full name can only contain letters and spaces';
                }
                return '';

            case 'email':
                if (!value) {
                    return 'Email is required';
                }
                if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
                    return 'Invalid email address';
                }
                return '';

            case 'password':
                if (!value) {
                    return 'Password is required';
                }
                if (value.length < 8) {
                    return 'Password must be at least 8 characters long';
                }
                if (!/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])/.test(value)) {
                    return 'Password must include lowercase, uppercase, number, and special character';
                }
                return '';

            case 'acceptTerms':
                if (!value) {
                    return 'You must accept the terms of service and privacy policy';
                }
                return '';

            default:
                return '';
        }
    };

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        const fieldValue = type === 'checkbox' ? checked : value;

        setFormData(prev => ({
            ...prev,
            [name]: fieldValue
        }));

        if (touched[name]) {
            const fieldError = validateField(name, fieldValue);
            setErrors(prev => ({
                ...prev,
                [name]: fieldError
            }));
        }
    };

    const handleBlur = (event) => {
        const { name, value, type, checked } = event.target;
        const fieldValue = type === 'checkbox' ? checked : value;

        setTouched(prev => ({
            ...prev,
            [name]: true
        }));

        const fieldError = validateField(name, fieldValue);
        setErrors(prev => ({
            ...prev,
            [name]: fieldError
        }));
    };

    const validateForm = () => {
        const newErrors = {};
        const newTouched = {};

        Object.keys(formData).forEach(field => {
            newErrors[field] = validateField(field, formData[field]);
            newTouched[field] = true;
        });

        setErrors(newErrors);
        setTouched(newTouched);

        return !Object.values(newErrors).some(error => error !== '');
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (validateForm()) {
            console.log('Form submitted', formData);
            navigate('/login');
        }
    };

    return (
        <Container
            component="main"
            maxWidth={false}
            sx={{
                minHeight: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#f5f5f5',
                padding: 3
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    maxWidth: 450,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {/* Header Section */}
                <Box
                    sx={{
                        width: '100%',
                        textAlign: 'center',
                        mb: 4
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mb: 8
                        }}
                    >
                       <img src={myIcon} alt="logo" width="250" height="50" />
                    </Box>
                    <Typography variant="h5" sx={{ mb: 1, color: '#5F5F5F', fontWeight: 500 }}>
                        Get started for free
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Or <Link href="/login" sx={{ color: '#00B894' }}>sign in to your account</Link>
                    </Typography>
                </Box>

                {/* Signup Form */}
                <Paper
                    elevation={2}
                    sx={{
                        width: '100%',
                        p: 4,
                        borderRadius: 2,
                        backgroundColor: 'white'
                    }}
                >
                    <Box component="form" onSubmit={handleSubmit}>
                        <TextField
                            margin="normal"
                            size='small'
                            required
                            fullWidth
                            label="Full Name"
                            name="fullName"
                            autoComplete="name"
                            value={formData.fullName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.fullName && !!errors.fullName}
                            helperText={touched.fullName && errors.fullName}
                            sx={{ mb: 2 }}
                        />

                        <TextField
                            margin="normal"
                            size='small'
                            required
                            fullWidth
                            label="Email Address"
                            name="email"
                            type="email"
                            autoComplete="email"
                            value={formData.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.email && !!errors.email}
                            helperText={touched.email && errors.email}
                            sx={{ mb: 2 }}
                        />

                        <TextField
                            margin="normal"
                            size='small'
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type={showPassword ? 'text' : 'password'}
                            autoComplete="new-password"
                            value={formData.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.password && !!errors.password}
                            helperText={touched.password && errors.password}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleClickShowPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            sx={{ mb: 2 }}
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="isAdmin"
                                    color="primary"
                                    checked={formData.isAdmin}
                                    onChange={handleChange}
                                />
                            }
                            label="Admin?"
                            sx={{ mb: 1, color: '#5F5F5F' }}
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="acceptTerms"
                                    color="primary"
                                    checked={formData.acceptTerms}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            }
                            label={
                                <Typography variant="body2">
                                    I accept the{' '}
                                    <Link href="#" sx={{ color: '#00B894' }}>
                                        terms of service
                                    </Link>{' '}
                                    &{' '}
                                    <Link href="#" sx={{ color: '#00B894' }}>
                                        privacy policy
                                    </Link>
                                </Typography>
                            }
                            sx={{ color: '#5F5F5F' }}
                        />
                        {touched.acceptTerms && errors.acceptTerms && (
                            <FormHelperText error sx={{ mt: 0 }}>{errors.acceptTerms}</FormHelperText>
                        )}

                        <Button
                            type="submit"
                            size='small'
                            fullWidth
                            variant="outlined"
                            sx={{
                                mt: 3,
                                mb: 2,
                                '&:hover': {
                                    backgroundColor: '#009B7D',
                                    color: 'white'
                                },
                                borderRadius: 1.5,
                                border: '2px solid #009B7D',
                                textTransform: 'none',
                                fontSize: '1rem',
                                color: '#525455',
                                fontWeight: 700
                            }}
                        >
                            Sign Up
                        </Button>
                    </Box>
                </Paper>
            </Box>
        </Container>
    );
};

export default SignupPage;