// src/ProtectedRoute.js
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { getCurrentUser } from '@aws-amplify/auth';

const ProtectedRoute = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);

    useEffect(() => {
        const checkUser = async () => {
            try {
                const currentUser = await getCurrentUser(); // Checks if the user is logged in
                if (currentUser !== null) {
                    setIsAuthenticated(true);
                }
            } catch (error) {
                setIsAuthenticated(false);
            }
        };

        checkUser();
    }, []);

    if (isAuthenticated === null) {
        return <div>Loading...</div>; // Show a loading spinner or placeholder while checking
    }

    return isAuthenticated ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;
