

import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { styled } from '@mui/material/styles';

const DM_SANS_TAB = styled(Tab)(({ theme }) => ({
  fontFamily: 'DM Sans',
  fontSize: '16px',
  fontWeight: 300, // Default font weight
  lineHeight: '20px',
  textAlign: 'left',
  textUnderlinePosition: 'from-font',
  textDecorationSkipInk: 'none',
  textTransform: 'none', 
  color: theme.palette.text.primary, 
  '&.Mui-selected': {
    fontWeight: 600, 
    color: '#00B894', 
    fontFamily: 'DM Sans',
  },
}));

const StyledTabList = styled(TabList)(({ theme }) => ({
  display: 'flex', // Ensure flexbox for alignment
  justifyContent: 'flex-start', // Align items to the start
  '& .MuiTabs-indicator': {
    backgroundColor: '#00B894', 
  },
}));
const CommonTabs = ({ value, handleChange, tabs }) => {
  return (
    <Box sx={{ width: '100%', typography: 'body1', marginTop: '0.8rem' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <StyledTabList onChange={handleChange} aria-label="lab API tabs example">
            {tabs.map((tab) => (
              <DM_SANS_TAB 
                key={tab.value} 
                label={tab.label} 
                value={tab.value} 
              />
            ))}
          </StyledTabList>
        </Box>
        {tabs.map((tab) => (
          <TabPanel key={tab.value} value={tab.value}>
            {tab.content} 
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
};

export default CommonTabs;