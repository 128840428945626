import React from "react";
import { Box } from "@mui/material";
import CommonSubHeader from "../../CommonComponent/CommonSubHeader";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import ButtonCommon from "../../CommonComponent/ButtonCommon";
import { Add } from "@mui/icons-material";

const DM_SANS = styled("span")({
  fontFamily: "DM Sans",
});

const CategoryCards = () => {

    const handleClick = () => {
        console.log("Add Services");
      };
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "2", p:'0' }}>
    <Grid
      container
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "18rem",
        background: "#F9FAFB",
        borderRadius: "12px",
        typography: "body1",
        p: 0,
        mt: "4px",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <CommonSubHeader
          mediumTitle="Active Services"
          subTitle="Data Processing Services (DPS) are services that handle, manage, and process data on behalf of an organisation."
        />
        <Box sx={{ p: "1.2rem" }}>
          {/* <Button variant="outlined" startIcon={<DeleteIcon />}></Button> */}
          <ButtonCommon size="small" icon={<Add />} onClick={handleClick}>
             Add a Service
          </ButtonCommon>
        </Box>
      </Box>

      <Box sx={{ height: "11rem", p: "1.2rem", pt: "2px" }}>
        <Box
          sx={{
            background: "#FFFFFF",
            height: "10rem",
            borderRadius: "12px",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box></Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            pt: "6px",
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{ fontSize: "14px", lineHeight: "26px" }}
            variant="body"
            color="#989898"
          >
            <DM_SANS>No More Results</DM_SANS>
          </Typography>
        </Box>
      </Box>
    </Grid>

  
  </Box>
  )
}

export default CategoryCards
