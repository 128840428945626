import React from "react";
import { Box, Paper, Chip, Switch, Tooltip, IconButton } from "@mui/material";
import CommonSubHeader from "../../CommonComponent/CommonSubHeader";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import ButtonCommon from "../../CommonComponent/ButtonCommon";
import { Add, StorageOutlined, InfoOutlined } from "@mui/icons-material";

const DM_SANS = styled("span")({
  fontFamily: "DM Sans",
});

const preferences = [
  {
    id: "google-analytics",
    name: "Google Analytics",
    description:
      "Data Privacy manages and stores user consent preferences, enabling the website to respect and apply your choices regarding data usage.",
    isRequired: true,
    isEssential: true,
    storageItems: 2,
    isActive: false,
  }
];

export const ServicesCards = () => {
  const handleClick = () => {
    console.log("Add Services");
  };

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "2", p: "0" }}>
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "18rem",
            background: "#F9FAFB",
            borderRadius: "12px",
            typography: "body1",
            p: 0,
            mt: "4px",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <CommonSubHeader
              mediumTitle="Active Services"
              subTitle="Data Processing Services (DPS) are services that handle, manage, and process data on behalf of an organisation."
            />
            <Box sx={{ p: "1.2rem" }}>
              {/* <Button variant="outlined" startIcon={<DeleteIcon />}></Button> */}
              <ButtonCommon size="small" icon={<Add />} onClick={handleClick}>
                Add a Service
              </ButtonCommon>
            </Box>
          </Box>

          <Box sx={{ height: "11rem", p: "1.2rem", pt: "2px" }}>
            <Box
              sx={{
                background: "#FFFFFF",
                height: "10rem",
                borderRadius: "12px",
                p: 2
              }}
            >
                <Box sx={{ width: "100%" }}>
                  {preferences.map((preference) => (
                    <Paper
                      key={preference.id}
                      elevation={0}
                      sx={{
                        p: 2,
                        mb: 2,
                        border: "1px solid",
                        borderColor: "divider",
                        borderRadius: 2,
                      }}
                    >
                      <Box
                        sx={{ display: "flex", alignItems: "center", mb: 1 }}
                      >
                        {/* Service Icon and Name */}
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flex: 1,
                          }}
                        >
                          <img
                            src={`/icons/${preference.id}.png`}
                            alt={preference.name}
                            style={{ width: 24, height: 24, marginRight: 8 }}
                          />
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: 500 }}
                          >
                            {preference.name}
                          </Typography>
                        </Box>

                        {/* Required/Essential Tags */}
                        <Box sx={{ display: "flex", gap: 1, mr: 2 }}>
                          {preference.isRequired && (
                            <Chip
                              label="Required"
                              size="small"
                              sx={{
                                bgcolor: "primary.light",
                                color: "primary.main",
                                fontWeight: 500,
                              }}
                            />
                          )}
                          {preference.isEssential && (
                            <Chip
                              label="Essential"
                              size="small"
                              sx={{
                                bgcolor: "grey.100",
                                color: "grey.700",
                                fontWeight: 500,
                              }}
                            />
                          )}
                        </Box>

                        {/* Storage Items and Switch */}
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 1 }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mr: 2,
                            }}
                          >
                            <StorageOutlined
                              sx={{
                                fontSize: 20,
                                color: "text.secondary",
                                mr: 0.5,
                              }}
                            />
                            <Typography variant="body2" color="text.secondary">
                              {preference.storageItems} Storage Items
                            </Typography>
                          </Box>
                          <Switch
                            checked={preference.isActive}
                            onChange={(e) => {}}
                            disabled={preference.isRequired}
                          />
                        </Box>
                      </Box>

                      {/* Description */}
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <Typography variant="body2" color="text.secondary">
                          {preference.description}
                        </Typography>
                        <Tooltip title="More information">
                          <IconButton size="small">
                            <InfoOutlined sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Paper>
                  ))}
                </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                pt: "6px",
                width: "100%",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ fontSize: "14px", lineHeight: "26px" }}
                variant="body"
                color="#989898"
              >
                <DM_SANS>No More Results</DM_SANS>
              </Typography>
            </Box>
          </Box>
        </Grid>

        {/* <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "18rem",
            background: "#F9FAFB",
            borderRadius: "12px",
            typography: "body1",
            p: 0,
            mt: "4px",
          }}
        >
          <CommonSubHeader
            mediumTitle="Active Services"
            subTitle="Data Processing Services (DPS) are services that handle, manage, and process data on behalf of an organisation."
          />
          <Box sx={{ background: "#FFFFFF", height: "12rem", p: "1.2rem" }}>
            <Box sx={{ background: "#FFFFFF" }}>sda</Box>
          </Box>
        </Box> */}
      </Box>
    </>
  );
};
