import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AutorenewOutlined from '@mui/icons-material/AutorenewOutlined';
import { styled } from '@mui/material/styles';

const DM_SANS = styled('span')({
  fontFamily: 'DM Sans', 
});

const CommonHeader = ({ title, subTitle,showIcon = false }) => {
  return (
    <Box component="main" sx={{ flexGrow: 1 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Box>
          <Typography variant="h5" color='#525455' fontWeight="bold">
            <DM_SANS>{title}</DM_SANS> 
          </Typography>
          <Typography variant="body" color="#989898">
            <DM_SANS>{subTitle}   {showIcon && <AutorenewOutlined fontSize='8px' />} 
            </DM_SANS> 
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CommonHeader;