import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  Typography,
  Grid,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Chip,
  CardContent,
} from "@mui/material";
import {
  ExpandMoreOutlined,
  AutorenewOutlined,
  Add,
} from "@mui/icons-material";
import { AlarmIcon, BriefcaseIcon, StatusUp, UserSquare } from "../assets/svg";
import ButtonCommon from "../CommonComponent/ButtonCommon";
import axios from "axios";

const WebsiteScan = () => {
  const StatCard = ({ icon, title, value, last30Days = true }) => (
    <Card
      variant="outlined"
      sx={{
        p: 2,
        height: "100%",
        borderRadius: 2,
        bgcolor: "white",
        pb: 0,
        pl: 0,
        pr: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
          pl: 3,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Box
            sx={{
              width: 30,
              height: 30,
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: "rgba(0, 200, 150, 0.1)",
              mr: 1,
            }}
          >
            {icon}
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{ fontWeight: "400", color: "#989898" }}
            >
              {title}
            </Typography>
            <Typography
              variant="h6"
              sx={{ mb: 1, fontWeight: "bold", color: "#525455" }}
            >
              {value.toLocaleString()}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          background: "#F9FAFB",
          pl: 2,
        }}
      >
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ fontSize: "12px" }}
        >
          {websiteScanData[0].scan_start_time ? `Scanned on ${websiteScanData[0].scan_start_time}` : ""}
        </Typography>
      </Box>
    </Card>
  );

  const statusChips = [
    { label: "Queue", color: "warning" },
    { label: "In Progress", color: "info" },
    { label: "Completed", color: "success" },
  ];

  const [websiteScanData, setWebsiteScanData] = useState([
    {
      scan_id: 1,
      url_name: "datavizz.in",
      scan_status: "COMPLETED",
      is_our_script: true,
      scan_start_time: "2024-12-11 11:11:11 IST",
      uncategorised: [
        "cookiechimp_uuid",
        "li_sugr",
        "bcookie",
        "lidc",
        "UserMatchHistory",
        "AnalyticsSyncHistory",
        "bscookie",
        "li_adsId",
      ],
      scan_data: [
        {
          category: "Essential",
          services: [
            {
              service_name: "Cloudflare",
              storage_items: ["__cf_test", "cf_clearance", "_cfuvid"],
            },
            {
              service_name: "Google reCAPTCHA",
              storage_items: ["_GRECAPTCHA"],
            },
          ],
        },
        {
          category: "Analytics",
          services: [
            {
              service_name: "Google Analytics",
              storage_items: ["_ga", "_gid", "_gat"],
            },
          ],
        },
      ],
    },
  ]);

  const categoryIcons = {
    Essential: <AlarmIcon />,
    Analytics: <StatusUp />,
    Marketing: <BriefcaseIcon />,
    Default: <AlarmIcon />, // Fallback icon
  };

  const navigate = useNavigate();

  const scanWebsite = async () => {
    try {
      const response = await axios.post(
        "http://192.168.1.62:3001/website-scan/scan",
        {
          organization_id: 1,
          website_id: 1,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response.data.data, "=====> scan website");
      setWebsiteScanData(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    scanWebsite();
  }, []);

  const totalStorageItems = websiteScanData[0]?.scan_data.reduce(
    (total, categoryData) => {
      return (
        total +
        categoryData.services.reduce(
          (sum, service) => sum + service.storage_items.length,
          0
        )
      );
    },
    0
  );

  return (
    <Box sx={{ display: "flex" }}>
      <Box component="main" sx={{ flexGrow: 1 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
          <Box>
            <Typography variant="h5" color="#525455" fontWeight="bold">
              Website Scan
            </Typography>
            <Typography variant="body2" color="#989898">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <ButtonCommon
              size="small"
              icon={<Add />}
              onClick={() => navigate("/add-website")}
            >
              Add New Website
            </ButtonCommon>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="body" color="#525455">
            Scan Report
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              flexDirection: "column",
            }}
          >
            <Typography variant="body" color="#525455">
              Scan Quota
            </Typography>
          </Box>
        </Box>

        {/* URL and Status Row */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <Typography
              variant="h6"
              color="#525455"
              sx={{
                fontWeight: "bold",
              }}
            >
              {websiteScanData.length !== "null"
                ? `https://${websiteScanData[0]?.url_name}`
                : ""}
            </Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              {statusChips.map((chip) => (
                <Chip
                  key={chip.label}
                  label={chip.label}
                  color={chip.color}
                  size="small"
                  sx={{ borderRadius: 3 }}
                />
              ))}
              <IconButton size="small">
                <AutorenewOutlined />
              </IconButton>
            </Stack>
          </Box>
          <Typography variant="h6" fontWeight="bold" color="#525455">
            1/5
          </Typography>
        </Box>

        {/* Cookie Count Row */}
        <Box>
          <Typography variant="body2" color="text.secondary">
            Cookie in Total:{" "}
            <Typography component="span" fontWeight="bold">
              {totalStorageItems !== "null" ? totalStorageItems : 0 }
            </Typography>
          </Typography>
        </Box>

        <Grid container spacing={2} sx={{ mb: 4, mt: 1 }}>
          <Grid item xs={12} md={3}>
            <StatCard
              icon={<AlarmIcon sx={{ color: "#00B894" }} />}
              title="Essential"
              value={
                websiteScanData[0]?.scan_data
                  .find((data) => data.category === "Essential")
                  ?.services.reduce(
                    (sum, service) => sum + service.storage_items.length,
                    0
                  ) || "-"
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <StatCard
              icon={<BriefcaseIcon sx={{ color: "#00B894" }} />}
              title="Marketing"
              value={
                websiteScanData[0]?.scan_data
                  .find((data) => data.category === "Marketing")
                  ?.services.reduce(
                    (sum, service) => sum + service.storage_items.length,
                    0
                  ) || "-"
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <StatCard
              icon={<StatusUp sx={{ color: "#00B894" }} />}
              title="Analytics"
              value={
                websiteScanData[0]?.scan_data
                  .find((data) => data.category === "Analytics")
                  ?.services.reduce(
                    (sum, service) => sum + service.storage_items.length,
                    0
                  ) || "-"
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <StatCard
              icon={<UserSquare sx={{ color: "#00B894" }} />}
              title="Personalisation"
              value={
                websiteScanData[0]?.scan_data
                  .find((data) => data.category === "Personalisation")
                  ?.services.reduce(
                    (sum, service) => sum + service.storage_items.length,
                    0
                  ) || "-"
              }
            />
          </Grid>
        </Grid>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          {(websiteScanData[0]?.scan_data || []).map((categoryData, index) => (
            <Accordion
              key={index}
              sx={{
                borderRadius: "12px",
                overflow: "hidden",
                "&:before": {
                  display: "none",
                },
              }}
              variant="outlined"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreOutlined />}
                sx={{
                  backgroundColor: "#F9FAFB",
                  borderBottom: (theme) =>
                    `1px solid ${
                      theme.palette.mode === "light" ? "#e0e0e0" : "#303030"
                    }`,
                  "& .MuiAccordionSummary-content": {
                    alignItems: "center",
                    display: "flex",
                    gap: 1,
                  },
                }}
              >
                <Box
                  component="span"
                  sx={{
                    width: "24px",
                    height: "24px",
                    backgroundColor: "rgba(0, 200, 150, 0.1)",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#fff",
                    fontSize: "14px",
                  }}
                >
                  {categoryIcons[categoryData?.category] ||
                    categoryIcons.Default}
                </Box>
                <Typography color="#525455" fontWeight="500">
                  {categoryData?.category}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: "#F9FAFB" }}>
                <Grid container spacing={1}>
                  {(categoryData?.services || []).map((service) => (
                    <Box key={service?.service_name} p={3}>
                      <Card variant="outlined">
                        <CardContent>
                          <Box display="flex" alignItems="center">
                            <ExpandMoreOutlined fontSize="medium" />
                            <Typography
                              variant="body"
                              component="div"
                              sx={{ ml: 1 }}
                              color="#525455"
                              fontWeight="bold"
                            >
                              {service?.service_name}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "15px",
                              mt: 1,
                            }}
                          >
                            {service?.storage_items?.map((item, index) => (
                              <Chip
                                key={index}
                                variant="filled"
                                color="primary"
                                label={item}
                                sx={{
                                  backgroundColor: "#F0F0F0",
                                  color: "#525455",
                                }}
                              />
                            ))}
                          </Box>
                        </CardContent>
                      </Card>
                    </Box>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))}

          {/* Uncategorised section */}
          <Box sx={{ mt: 2 }}>
            <Accordion
              sx={{
                borderRadius: "12px",
                overflow: "hidden",
                "&:before": {
                  display: "none",
                },
              }}
              variant="outlined"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreOutlined />}
                sx={{
                  backgroundColor: "#F9FAFB",
                  borderBottom: (theme) =>
                    `1px solid ${
                      theme.palette.mode === "light" ? "#e0e0e0" : "#303030"
                    }`,
                  "& .MuiAccordionSummary-content": {
                    alignItems: "center",
                    display: "flex",
                    gap: 1,
                  },
                }}
              >
                <Box
                  component="span"
                  sx={{
                    width: "24px",
                    height: "24px",
                    backgroundColor: "rgba(0, 200, 150, 0.1)",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#fff",
                    fontSize: "14px",
                  }}
                >
                  <UserSquare />
                </Box>
                <Typography color="#525455" fontWeight="500">
                  Uncategorised
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: "#F9FAFB" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                  }}
                >
                  {(websiteScanData[0]?.uncategorised || []).map(
                    (item, index) => (
                      <Chip
                        key={index}
                        variant="filled"
                        label={item}
                        sx={{
                          backgroundColor: "#F0F0F0",
                          color: "#525455",
                        }}
                      />
                    )
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default WebsiteScan;
