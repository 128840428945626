import React, { useState } from 'react';
import {
    Box,
    Container,
    Typography,
    TextField,
    Button,
    Link,
    Paper,
    Snackbar
} from '@mui/material';
import myIcon from '../assets/svg/logo.svg';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { useNavigate } from 'react-router-dom';
import { signIn } from '@aws-amplify/auth';

const LoginPage = () => {
    const [showPassword, setShowPassword] = React.useState(false);
    const [formData, setFormData] = React.useState({
        email: '',
        password: '',
    });
    const [errors, setErrors] = React.useState({});
    const [touched, setTouched] = React.useState({});
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [error, setError] = useState('');

    const navigate = useNavigate();

    const handleClickShowPassword = () => setShowPassword(!showPassword);

    const validateField = (name, value) => {
        switch (name) {
            case 'email':
                if (!value) {
                    return 'Email is required';
                }
                if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
                    return 'Invalid email address';
                }
                return '';

            case 'password':
                if (!value) {
                    return 'Password is required';
                }
                if (value.length < 8) {
                    return 'Password must be at least 8 characters long';
                }
                if (!/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])/.test(value)) {
                    return 'Password must include lowercase, uppercase, number, and special character';
                }
                return '';

            default:
                return '';
        }
    };

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        const fieldValue = type === 'checkbox' ? checked : value;

        setFormData(prev => ({
            ...prev,
            [name]: fieldValue
        }));

        if (touched[name]) {
            const fieldError = validateField(name, fieldValue);
            setErrors(prev => ({
                ...prev,
                [name]: fieldError
            }));
        }
    };

    const handleBlur = (event) => {
        const { name, value, type, checked } = event.target;
        const fieldValue = type === 'checkbox' ? checked : value;

        setTouched(prev => ({
            ...prev,
            [name]: true
        }));

        const fieldError = validateField(name, fieldValue);
        setErrors(prev => ({
            ...prev,
            [name]: fieldError
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('');
        try {
            const { email, password } = formData;

            if (!email || !password) {
                console.log('Please enter both email and password');
                return;
            }

            const signInResponse = await signIn({
                username: email,
                password
            });

            console.log(signInResponse, "=========> sign in res");
            

            if (signInResponse) {
                setSnackbarOpen(true);
                console.log('Successfully logged in!');
                navigate('/dashboard');
            }
        } catch (err) {
            console.error('Error signing in:', err);
            if (err.name === 'NotAuthorizedException') {
                setError('Incorrect username or password');
            } else if (err.name === 'UserNotConfirmedException') {
                setError('Please verify your email before logging in');
            } else if (err.name === 'UserNotFoundException') {
                setError('User does not exist');
            } else {
                setError(err.message || 'Error signing in');
            }
        }
    };

    return (
        <Container
            component="main"
            maxWidth={false}
            sx={{
                minHeight: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#f5f5f5',
                padding: 3
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    maxWidth: 450,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        textAlign: 'center',
                        mb: 4
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mb: 12
                        }}
                    >
                        <img src={myIcon} alt="logo" width="250" height="50" />
                    </Box>
                    <Typography variant="h5" sx={{ mb: 1, color: '#5F5F5F', fontWeight: 500 }}>
                        Sign in to your account
                    </Typography>
                </Box>

                <Paper
                    elevation={2}
                    sx={{
                        width: '90%',
                        p: 4,
                        borderRadius: 2,
                        backgroundColor: 'white'
                    }}
                >
                    <Box component="form" onSubmit={handleSubmit}>
                        <TextField
                            margin="normal"
                            size='small'
                            required
                            fullWidth
                            label="Email Address"
                            name="email"
                            type="email"
                            autoComplete="email"
                            value={formData.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.email && !!errors.email}
                            helperText={touched.email && errors.email}
                            sx={{ mb: 2 }}
                        />

                        <TextField
                            margin="normal"
                            size='small'
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type={showPassword ? 'text' : 'password'}
                            autoComplete="current-password"
                            value={formData.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.password && !!errors.password}
                            helperText={touched.password && errors.password}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleClickShowPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            sx={{ mb: 2 }}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end', // Align to the right
                            }}
                        >
                            <Link
                                href="/forgot-password"
                                sx={{
                                    color: '#00B894',
                                    textDecoration: 'none',
                                    fontWeight: 500,
                                    fontSize: '16px'
                                }}
                            >
                                Forgot password?
                            </Link>
                        </Box>

                        <Button
                            type="submit"
                            size='small'
                            fullWidth
                            variant="outlined"
                            sx={{
                                mt: 2,
                                mb: 2,
                                '&:hover': {
                                    backgroundColor: '#009B7D',
                                    color: 'white'
                                },
                                borderRadius: 1.5,
                                border: '2px solid #009B7D',
                                textTransform: 'none',
                                fontSize: '1rem',
                                color: '#525455',
                                fontWeight: 700
                            }}
                        >
                            Sign In
                        </Button>
                        <Snackbar
                            open={snackbarOpen}
                            autoHideDuration={3000} // Automatically close after 3 seconds
                            onClose={() => setSnackbarOpen(false)} // Close handler
                            message={"Successfully logged in!"}
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Position: Top-right
                        />
                        {error}
                    </Box>
                </Paper>
            </Box>
        </Container>
    );
};

export default LoginPage;