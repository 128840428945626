// index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Amplify } from 'aws-amplify';

// Configure Amplify
const config = {
  Auth: {
    Cognito: {
      userPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      region: process.env.REACT_APP_AWS_REGION,
      loginWith: {
        email: true,
        phone: false,
        username: true
      },
      authenticationFlowType: 'USER_SRP_AUTH'
    }
  }
};
Amplify.configure(config);

const root = ReactDOM.createRoot(document.getElementById("root"));
// wrap the application with AuthProvider
root.render(
  <React.StrictMode>
    {/* <AuthProvider {...cognitoAuthConfig}> */}
    <App />
    {/* </AuthProvider> */}
  </React.StrictMode>
);