import React from "react";
import {
  Box,
  Card,
  Typography,
  Grid,
  Select,
  LinearProgress,
  MenuItem,
  Divider,
} from "@mui/material";
import {
  RemoveRedEyeOutlined,
  PersonOutlineOutlined,
  CheckCircleOutline,
  TrendingUpOutlined,
  TrendingDownOutlined,
} from "@mui/icons-material";
import {
  LineChart,
  Line,
  PieChart,
  Pie,
  XAxis,
  YAxis,
  Tooltip,
  Cell,
  Legend,
} from "recharts";
import CommonHeader from "../../CommonComponent/CommonHeader";

const visitorData = Array(30)
  .fill()
  .map((_, i) => ({
    date: `Nov ${i + 9}`,
    pageViews: Math.floor(Math.random() * 1000 + 200),
    uniqueVisitors: Math.floor(Math.random() * 800 + 100),
  }));

const consentData = Array(30)
  .fill()
  .map((_, i) => ({
    date: `Nov ${i + 9}`,
    fullConsents: Math.floor(Math.random() * 300),
    customConsents: Math.floor(Math.random() * 250),
    onlyNecessary: Math.floor(Math.random() * 200),
    noDecision: Math.floor(Math.random() * 150),
  }));

const deviceData = [
  { name: "Desktop", value: 60 },
  { name: "Tablet", value: 25 },
  { name: "Mobile", value: 15 },
  { name: "Unknown", value: 20 },
];

const countries = [
  { name: "United State", value: 5807, growth: 15.7, progress: 70 },
  { name: "United Kingdom", value: 5807, growth: 15.7, progress: 60 },
  { name: "Spain", value: 5807, growth: 15.7, progress: 90 },
  { name: "France", value: 5807, growth: 15.7, progress: 50 },
  { name: "Germany", value: 5807, growth: 15.7, progress: 40 },
];

const Dashboard = () => {
  const StatCard = ({ icon, title, value, growth, last30Days = true }) => (
    <Card
      variant="outlined"
      sx={{
        p: 3,
        height: "100%",
        borderRadius: 2,
        bgcolor: "white",
        pb: 0,
        pl: 0,
        pr: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
          pl: 3,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Box
            sx={{
              width: 40,
              height: 40,
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: "rgba(0, 200, 150, 0.1)",
              mr: 2,
            }}
          >
            {icon}
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{ fontWeight: "400", color: "#989898" }}
            >
              {title}
            </Typography>
            <Typography
              variant="h6"
              sx={{ mb: 1, fontWeight: "bold", color: "#525455" }}
            >
              {value.toLocaleString()}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            background: growth >= 0 ? "#F0FFFC" : "#FFEFF2",
            padding: "4px 8px",
            borderRadius: 2,
            mr: 2,
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: growth >= 0 ? "#00B894" : "#FF2E57",
              fontWeight: "bold",
            }}
          >
            {growth >= 0 ? (
              <TrendingUpOutlined fontSize="8px" />
            ) : (
              <TrendingDownOutlined fontSize="8px" />
            )}{" "}
            {growth >= 0 ? "+" : ""}
            {growth}%
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          background: "#F9FAFB",
          pl: 3,
        }}
      >
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ fontSize: "0.875rem" }}
        >
          {last30Days ? "last 30 days" : ""}
        </Typography>
      </Box>
    </Card>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <Box component="main" sx={{ flexGrow: 1 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
          <CommonHeader
            title="Dashboard"
            subTitle="November 09, 2024 - December 09, 2024"
            showIcon
          />
          <Box sx={{ display: "flex" }}>
            <Select
              value={"10"}
              displayEmpty
              inputProps={{ "aria-label": "Select time period" }}
              sx={{
                textTransform: "capitalize",
                color: "black",
                minWidth: 120,
                height: 40,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "green",
                  },
                  "&:focus fieldset": {
                    borderColor: "green",
                  },
                },
              }}
            >
              <MenuItem value={10}>Last 30 Days</MenuItem>
              <Divider />
              <MenuItem value={20}>Last 60 Days</MenuItem>
              <Divider />
              <MenuItem value={30}>Last 90 Days</MenuItem>
            </Select>
          </Box>
        </Box>

        <Grid container spacing={3} sx={{ mb: 3 }}>
          <Grid item xs={12} md={4}>
            <StatCard
              icon={<RemoveRedEyeOutlined sx={{ color: "#00B894" }} />}
              title="Page Views"
              value={23998}
              growth={15.7}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <StatCard
              icon={<PersonOutlineOutlined sx={{ color: "#00B894" }} />}
              title="Unique Visitors"
              value={23998}
              growth={-16.5}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <StatCard
              icon={<CheckCircleOutline sx={{ color: "#00B894" }} />}
              title="Countries"
              value={23998}
              growth={15.7}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card
              variant="outlined"
              sx={{
                p: 3,
                height: "100%",
                borderRadius: 2,
                border: "1px solid #D7D7D7",
              }}
            >
              <Typography
                variant="h6"
                gutterBottom
                fontWeight="bold"
                sx={{ color: "#525455", mb: 2 }}
              >
                Visitors
              </Typography>
              <LineChart
                width={700}
                height={250}
                data={visitorData}
                margin={{
                  top: 8,
                  right: 30,
                  left: 20,
                  bottom: 5,
                  color: "#525455",
                }}
              >
                <XAxis
                  dataKey="date"
                  stroke="#999"
                  tick={{ fill: "#525455", fontSize: 12, fontWeight: 400 }}
                />
                <YAxis
                  stroke="#999"
                  tick={{ fill: "#525455", fontSize: 12, fontWeight: 400 }}
                />
                <Tooltip />
                <Line
                  type="monotone"
                  dataKey="pageViews"
                  stroke="#00B894"
                  strokeWidth={1}
                  dot={true}
                />
                <Line
                  type="monotone"
                  dataKey="uniqueVisitors"
                  stroke="#3CC3DF"
                  strokeWidth={1}
                  dot={false}
                  strokeDasharray="5 5"
                />
                <Legend layout="horizontal" verticalAlign="top" align="right" />
              </LineChart>
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card
              variant="outlined"
              sx={{
                p: 3,
                height: "100%",
                borderRadius: 2,
                border: "1px solid #D7D7D7",
              }}
            >
              <Typography
                variant="h6"
                gutterBottom
                fontWeight="bold"
                sx={{ color: "#525455", mb: 2 }}
              >
                Countries
              </Typography>
              {countries.map((country) => (
                <Box
                  key={country.name}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 2,
                    gap: 2,
                  }}
                >
                  {/* Left Section */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flex: 1,
                      gap: 1,
                    }}
                  >
                    <Box
                      sx={{
                        width: 35,
                        height: 35,
                        borderRadius: "50%",
                        border: "1px solid #DADADA",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={`path-to-flags/${country.name.replace(
                          " ",
                          "_"
                        )}.png`}
                        alt={`${country.name} flag`}
                        style={{ width: 16, height: 16 }}
                      />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <Typography
                        variant="body2"
                        color="#525455"
                        sx={{ fontWeight: 400, marginBottom: 0.5 }}
                      >
                        {country.name}
                      </Typography>
                      <LinearProgress
                        variant="determinate"
                        value={country.progress}
                        sx={{
                          height: 4,
                          borderRadius: 3,
                          backgroundColor: "#F0F0F0",
                          "& .MuiLinearProgress-bar": {
                            backgroundColor: "#00B894",
                          },
                        }}
                      />
                    </Box>
                  </Box>

                  {/* Right Section */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        background: "#F0FFFC",
                        padding: "4px 8px",
                        borderRadius: 2,
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: 600, color: "#00B894" }}
                      >
                        <TrendingUpOutlined fontSize="8px" /> {country.growth}%
                      </Typography>
                    </Box>
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: 500,
                        color: "#525455",
                      }}
                    >
                      {country.value.toLocaleString()}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Card>
          </Grid>

          {/* Consents Chart */}
          <Grid item xs={12} md={8}>
            <Card
              variant="outlined"
              sx={{
                p: 3,
                height: "100%",
                borderRadius: 2,
                border: "1px solid #D7D7D7",
              }}
            >
              <Typography
                variant="h6"
                gutterBottom
                fontWeight="bold"
                sx={{ color: "#525455", mb: 2 }}
              >
                Consents
              </Typography>
              <LineChart
                width={700}
                height={300}
                data={consentData}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <XAxis
                  dataKey="date"
                  stroke="#999"
                  tick={{ fill: "#525455", fontSize: 12, fontWeight: 400 }}
                />
                <YAxis
                  stroke="#999"
                  tick={{ fill: "#525455", fontSize: 12, fontWeight: 400 }}
                />
                <Tooltip />
                <Line
                  type="monotone"
                  dataKey="fullConsents"
                  stroke="#6366f1"
                  strokeWidth={1}
                  dot={false}
                />
                <Line
                  type="monotone"
                  dataKey="customConsents"
                  stroke="#f59e0b"
                  strokeWidth={1}
                  dot={false}
                />
                <Line
                  type="monotone"
                  dataKey="onlyNecessary"
                  stroke="#10b981"
                  strokeWidth={1}
                  dot={false}
                />
                <Line
                  type="monotone"
                  dataKey="noDecision"
                  stroke="#ef4444"
                  strokeWidth={1}
                  dot={false}
                />
                <Legend layout="horizontal" verticalAlign="top" align="right" />
              </LineChart>
            </Card>
          </Grid>

          {/* Devices Chart */}
          <Grid item xs={12} md={4}>
            <Card
              variant="outlined"
              sx={{
                p: 3,
                height: "100%",
                borderRadius: 2,
                border: "1px solid #D7D7D7",
              }}
            >
              <Typography
                variant="h6"
                gutterBottom
                fontWeight="bold"
                sx={{ color: "#525455", mb: 2 }}
              >
                Devices
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <PieChart width={400} height={300}>
                  {/* Main Pie Chart */}
                  <Pie
                    data={deviceData}
                    cx="50%"
                    cy="50%"
                    innerRadius={80}
                    outerRadius={120}
                    fill="#8884d8"
                    dataKey="value"
                    nameKey="name"
                    label={false}
                  >
                    {deviceData.map((_, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={
                          ["#10b981", "#6366f1", "#1E90FF", "#D7D7D7"][index]
                        }
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend
                    verticalAlign="top"
                    align="left"
                    iconType="circle"
                    formatter={(value) => (
                      <span style={{ color: "#525455", fontSize: "14px" }}>
                        {value}
                      </span>
                    )}
                  />
                </PieChart>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Dashboard;
