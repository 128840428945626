// App.js
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './components/layout/MainLayout';
import Dashboard from './pages/Dashboard/dashboard';
import WebsiteScan from './pages/WebsiteScan';
import LoginPage from './pages/LogIn';
import SignupPage from './pages/SignUp';
import ForgotPassword from './pages/ForgotPassword';
import ProtectedRoute from './ProtectedRoute';
import VendorManagement from './pages/VendorManagement/VendorManagement';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={
          <ProtectedRoute>
            <Layout />
          </ProtectedRoute>
        }>
          <Route index element={<Dashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/website-scan" element={<WebsiteScan />} />
          <Route path="/vendor-management" element={<VendorManagement />} />

        </Route>
        <Route path='*' element={<LoginPage />} />
        <Route path='/login' element={<LoginPage />} />
        <Route path='/signup' element={<SignupPage />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;